/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */

import React, { useEffect, useState } from 'react';
import { shape, string, array, node, number, bool } from 'prop-types';
import styled from 'styled-components';
import FlexWrapper from 'TMComponents/FlexWrapper';
import Img from 'TMComponents/ImageDynamic';
import { breakpoints } from 'TMConstants';
import imageProps from 'TMPropTypes/imageProps';
import Content from 'TMComponents/Content';
import debounce from 'TMUtils/debounce';

const Background = styled.div`
    position: relative;
    height: 100%;
    ${({ backgroundVariant }) =>
        backgroundVariant &&
        `
        background-color: ${backgroundVariant};
    `}
`;

const Sections = styled(FlexWrapper)`
    justify-content: space-around;
    flex-direction: column;
    max-width: 1280px;
    margin: auto;

    ${({ imagePosition }) =>
        imagePosition === 'above' &&
        `
        flex-direction: column-reverse;  
    `}
    @media (min-width: ${breakpoints.mobileTablet}px) {
        display: flex;
        ${({ imagePosition }) => {
            switch (imagePosition) {
                case 'left':
                    return 'flex-direction: row-reverse;';
                default:
                    return 'flex-direction: row;';
            }
        }}
    }
`;

const ContentSection = styled(FlexWrapper)`
    align-items: center;
`;

const ImageSection = styled(FlexWrapper)`
    ${({ mobileImageWidth }) => `
        @media (max-width: ${breakpoints.mobileTablet}px) {
            max-width: ${mobileImageWidth}%;
        }  
    `}

    @media (min-width: ${breakpoints.mobileTablet + 20}px) {
        max-width: 60%;
    }
`;

const Image = styled(Img)``;

const BackgroundImage = styled(Img)`
    position: absolute !important;
    right: 0;
    ${({ mobileBackgroundBottomAlign }) =>
        mobileBackgroundBottomAlign &&
        `
        bottom: 0;
    `}
    ${({ mobileImageWidth }) =>
        `
        max-width: ${mobileImageWidth}%;
        @media (min-width: ${breakpoints.mobile + 50}px) {
            max-width: ${mobileImageWidth - 10}%;
        }
        @media (min-width: ${breakpoints.mobile + 100}px) {
            max-width: ${mobileImageWidth - 25}%;
        }
    `}
`;

const Hero = ({
    desktopImage,
    mobileImage,
    desktopImagePosition,
    mobileImagePosition,
    mobileImageWidth = 100,
    mobileImageAsBackground = false,
    mobileBackgroundBottomAlign = false,
    backgroundVariant,
    content,
    children
}) => {
    const [image, setImage] = useState(mobileImage);
    const [position, setPosition] = useState(mobileImagePosition);
    const [viewportWidth, setViewportWidth] = useState(null);

    useEffect(() => {
        if (!viewportWidth) setViewportWidth(window.innerWidth);
        if (viewportWidth < breakpoints.mobileTablet - 100) {
            setImage(mobileImage);
            setPosition(mobileImagePosition);
        } else {
            setImage(desktopImage);
            setPosition(desktopImagePosition);
        }
    }, [viewportWidth]);

    useEffect(() => {
        window.addEventListener(
            'resize',
            debounce(() => setViewportWidth(window.innerWidth), 100)
        );
        /* istanbul ignore next */
        return () => {
            window.removeEventListener(
                'resize',
                debounce(() => setViewportWidth(window.innerWidth), 100)
            );
        };
    }, []);

    const headerContent = content.map((cmsComponent) => {
        if (
            cmsComponent.__typename === 'DatoCmsText' ||
            cmsComponent.__typename === 'DatoCmsTextStructured'
        ) {
            cmsComponent.allowH1 = true;
        }
        return cmsComponent;
    });

    const isDesktop = viewportWidth >= breakpoints.mobileTablet;
    const showMobileImageAsBackground =
        !isDesktop && mobileImageAsBackground;
    return (
        <Background backgroundVariant={backgroundVariant}>
            <Sections imagePosition={position}>
                <ContentSection>
                    <Content
                        backgroundVariant={backgroundVariant}
                        content={headerContent}
                        leftAlign={isDesktop}>
                        {children}
                    </Content>
                </ContentSection>

                {showMobileImageAsBackground && (
                    <BackgroundImage
                        imageData={image}
                        loading="eager"
                        mobileImageWidth={mobileImageWidth}
                        objectPosition="100%"
                        mobileBackgroundBottomAlign={
                            mobileBackgroundBottomAlign
                        }
                    />
                )}
                {!showMobileImageAsBackground && (
                    <ImageSection
                        imagePosition={position}
                        mobileImageWidth={mobileImageWidth}>
                        <Image
                            imageData={image}
                            loading="eager"
                            mobileImageWidth={mobileImageWidth}
                        />
                    </ImageSection>
                )}
            </Sections>
        </Background>
    );
};

Hero.propTypes = {
    children: node,
    desktopImage: shape(imageProps).isRequired,
    mobileImage: shape(imageProps),
    content: array.isRequired,
    desktopImagePosition: string.isRequired,
    mobileImagePosition: string.isRequired,
    mobileImageWidth: number,
    mobileImageAsBackground: bool,
    mobileBackgroundBottomAlign: bool,
    backgroundVariant: string
};

export default Hero;
