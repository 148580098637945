export default (slug) => {
    let baseRoute = '/';
    const breadcrumbs = [];

    slug.split('/').forEach((str) => {
        baseRoute += `${str}/`;
        const title = str
            .split('-')
            .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
            .join(' ');
        breadcrumbs.push({
            title,
            link: baseRoute
        });
    });

    return breadcrumbs;
};
