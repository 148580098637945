/* SEARCH-TAG: page-template landing-page  */
/* eslint-disable camelcase */
import React, {
    useState,
    useEffect,
    Suspense,
    lazy,
    useContext
} from 'react';
import { graphql } from 'gatsby';
import { shape } from 'prop-types';
import HeadTags from 'TMComponents/HeadTags';
import Main from 'TMComponents/Layout/Main';
import Canonical from 'TMComponents/Canonical';
import NoIndex from 'TMComponents/NoIndex';
import buildLandingBreadcrumb from 'TMUtils/buildLandingBreadcrumb';
import queryParamsToObj from 'TMUtils/queryParamsToObj';
import isServerSideRender from 'TMUtils/isServerSideRender';
import { fireGAEvent } from 'TMUtils/dataLayerPush';
import Hero from 'TMComponents/Hero';
import { StickyFooterContextProvider } from 'TMComponents/StickyFooter';
import { BannerContext } from 'TMComponents/Banner';
import { BreadcrumbsContext } from 'TMComponents/Breadcrumbs';
import { LayoutContext } from '../components/Layout';

const ServerSideRender = isServerSideRender();
const Content = lazy(() => import('TMComponents/Content'));

const LandingPage = ({ data: { landingPage } }) => {
    const [concealerHub, setConcealerHub] = useState({});
    const isBannerAllowed = false;
    const isCrumbsAllowed = false;

    // When the component has mounted, if there is a window.datalayer (GTM) then push a view event
    useEffect(() => {
        if (window.dataLayer) {
            const utms = queryParamsToObj(window.location.search);
            const { utmSource, utmMedium, utmCampaign, utmContent } = utms;

            if (utmContent && utmMedium && utmCampaign && utmSource) {
                const payload = {
                    ...utms,
                    platform: 'web'
                };
                fireGAEvent(
                    'lpview',
                    'view',
                    'landing page',
                    utmSource,
                    payload
                );
            }
        }
    }, []);

    const { setIsNavHidden } = useContext(LayoutContext);
    const { setShowBanner } = useContext(BannerContext);
    const { setCrumbs } = useContext(BreadcrumbsContext);

    useEffect(() => {
        setIsNavHidden(landingPage.hideNav);
        setShowBanner(isBannerAllowed);
        setCrumbs({
            show: isCrumbsAllowed,
            crumbList: buildLandingBreadcrumb(landingPage.slug)
        });
    }, []);

    return (
        <StickyFooterContextProvider
            value={{ concealerHub, setConcealerHub }}>
            <Main
                isBannerAllowed={isBannerAllowed}
                isCrumbsAllowed={isCrumbsAllowed}>
                <HeadTags tags={landingPage.seoMetaTags.tags} />
                {landingPage.noIndex ? (
                    <NoIndex />
                ) : (
                    <Canonical href={landingPage.slug} />
                )}
                <Hero
                    content={landingPage.heroContent}
                    desktopImage={landingPage.headerImageDesktop}
                    mobileImage={landingPage.headerImageMobile}
                    desktopImagePosition={landingPage.desktopImagePosition}
                    mobileImagePosition={landingPage.mobileImagePosition}
                    mobileImageWidth={landingPage.mobileImageWidth}
                    backgroundVariant={landingPage.backgroundVariant}
                    mobileImageAsBackground={
                        landingPage.mobileImageAsBackground
                    }
                    mobileBackgroundBottomAlign={
                        landingPage.mobileBackgroundBottomAlign
                    }
                />
                {!ServerSideRender && (
                    <Suspense fallback={<div />}>
                        <Content
                            content={landingPage.content}
                            isPageContent
                        />
                    </Suspense>
                )}
            </Main>
        </StickyFooterContextProvider>
    );
};

/**
 * Fetch page data
 */

export const pageQuery = graphql`
    query ($slug: String!) {
        landingPage: datoCmsLandingPage(slug: { eq: $slug }) {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            slug
            hideNav
            noIndex
            id: originalId

            headerImageDesktop {
                gatsbyImageData(
                    width: 650
                    layout: CONSTRAINED
                    imgixParams: { auto: "compress" }
                )
            }

            headerImageMobile {
                gatsbyImageData(
                    width: 400
                    layout: CONSTRAINED
                    imgixParams: { auto: "compress" }
                )
            }
            desktopImagePosition
            mobileImagePosition
            mobileImageAsBackground
            mobileBackgroundBottomAlign
            mobileImageWidth
            backgroundVariant

            heroContent {
                ...appsFlyerButtonFields
                ...buttonFields
                ...panelFields
                ...stickyFooterConcealerFields
                ...qrCodeFields
                ...textStructuredFields
                ...imageFields
            }
            content {
                ...AccountComparisonFields
                ...AccountFeatureFields
                ...appsFlyerButtonFields
                ...AppStoreLinkFields
                ...buttonFields
                ...cardFields
                ...cardV2Fields
                ...currencyConverterFields
                ...customHTMLFields
                ...faqFields
                ...iconWithContentFields
                ...imageFields
                ...imageListFields
                ...imageWithContentFields
                ...infoCardFields
                ...lottieFields
                ...moreInformationFields
                ...otCookieListFields
                ...qrCodeFields
                ...panelFields
                ...qrCodeFields
                ...repExampleFields
                ...sendAppLinkFields
                ...separatorFields
                ...stickyFooterFields
                ...stickyFooterConcealerFields
                ...tableFields
                ...textFields
                ...textStructuredFields
                ...trustpilotReviewFields
                ...trustpilotCarouselFields
                ...videoFields
            }
        }
    }
`;

/**
 * Prop types
 */
LandingPage.propTypes = {
    data: shape({
        landingPage: shape({})
    }).isRequired
};

export default LandingPage;
