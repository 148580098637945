import React from 'react';
import { string } from 'prop-types';

import HeadTags from 'TMComponents/HeadTags';

const Canonical = ({ href }) => (
    <HeadTags>
        <link
            rel="canonical"
            href={`https://www.thinkmoney.co.uk/${href ? `${href}/` : ''}`}
        />
    </HeadTags>
);

Canonical.propTypes = {
    href: string
};

Canonical.defaultProps = {
    href: ''
};

export default Canonical;
